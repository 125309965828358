// extracted by mini-css-extract-plugin
export var circleButton = "circleButton-module--circleButton--r6iTY";
export var biggest = "circleButton-module--biggest--EMvqD";
export var bigger = "circleButton-module--bigger--zXL33";
export var buttonHoverContainer = "circleButton-module--buttonHoverContainer--LLT8h";
export var circleArrow = "circleButton-module--circleArrow--hc9YN circleButton-module--circleButton--r6iTY";
export var prev = "circleButton-module--prev--Vl9-7";
export var inactiveCircleArrow = "circleButton-module--inactiveCircleArrow--hqIRM circleButton-module--circleArrow--hc9YN circleButton-module--circleButton--r6iTY";
export var circlePlay = "circleButton-module--circlePlay--u-YIp circleButton-module--circleButton--r6iTY";
export var circlePlus = "circleButton-module--circlePlus--nU1Or circleButton-module--circleButton--r6iTY";
export var circleX = "circleButton-module--circleX--1CDdO circleButton-module--circleButton--r6iTY";
export var buttonText = "circleButton-module--buttonText--3LLFy";
export var textWhite = "circleButton-module--textWhite--cmNga";
export var buttonTextRight = "circleButton-module--buttonTextRight--gTQ5h circleButton-module--buttonText--3LLFy";
export var hasKicker = "circleButton-module--hasKicker--lYNdV";
export var smallLeft = "circleButton-module--smallLeft--FWxEM";
export var textStyleAlt = "circleButton-module--textStyleAlt--LO5HQ";
export var kicker = "circleButton-module--kicker--3WLI-";
export var slideUp = "circleButton-module--slideUp--wAQpm";
export var slideUpLarge = "circleButton-module--slideUpLarge--9bnvk";