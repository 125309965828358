// extracted by mini-css-extract-plugin
export var container = "projects-module--container--g8A1P";
export var cardGrid = "projects-module--cardGrid--aa3SS";
export var label = "projects-module--label--3COKr";
export var spacer = "projects-module--spacer--Jio3o";
export var logo = "projects-module--logo--taajH";
export var sectionHeading = "projects-module--sectionHeading--wsWRX";
export var partnersSection = "projects-module--partnersSection---qiTL";
export var partnerLogo = "projects-module--partnerLogo--pEloq";
export var collaborationsSection = "projects-module--collaborationsSection--07hFx";
export var sectionInset = "projects-module--sectionInset--J1kOj";
export var sectionItem = "projects-module--sectionItem--XWf+f";
export var partnerItem = "projects-module--partnerItem--bpp7j";
export var collabItem = "projects-module--collabItem--YAoyD";
export var collabImg = "projects-module--collabImg--DdKFF";
export var collabTitle = "projects-module--collabTitle--9Bihz";
export var angle = "projects-module--angle--KI8+3";
export var partner = "projects-module--partner--8xsA3";
export var authors = "projects-module--authors---L1Fh";
export var authorName = "projects-module--authorName--qMiQG";
export var fellowshipStories = "projects-module--fellowshipStories--KVG7v";
export var storyImg = "projects-module--storyImg--j0DBb";
export var storyTitle = "projects-module--storyTitle--Y4i06";
export var buttonNext = "projects-module--buttonNext--lHzoa";
export var slideUp = "projects-module--slideUp--BXvNS";
export var slideUpLarge = "projects-module--slideUpLarge--TBItZ";