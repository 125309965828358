import React from 'react';
import RoundedButton from '../buttons/roundedButton';
import * as style from './spotlightCard.module.scss';
import { navigate } from 'gatsby-link';

const SpotlightCard = (props) => {
	
	const {
		label,
		title,
		image,
		path,
	} = props.data;

	const inlineStyle = {};

	if (image) {
		const imgSrc = image.sizes.near_square_md;
		inlineStyle.backgroundImage = `url(${imgSrc})`;
	}

	return (
		<div className={style.container} style={inlineStyle}>
			<div className={style.backdrop}>
				<div>
					{title}
				</div>
				<RoundedButton type="button" text={label} onClick={() => navigate(path)}/>
			</div>
		</div>
	)
};

export default SpotlightCard;