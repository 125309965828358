import React from 'react';
import spinner from '../../assets/loading.gif';

export default function Loading(props) {
	const style = {
		width: props.width || 64,
		...props.style
	};

	const divStyle = {
		textAlign: props.align ? props.align : 'left',
	};

	return <div style={divStyle}>
		<img style={style} src={spinner} alt="loading" />
	</div>;
}