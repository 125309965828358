import React from 'react';
import * as style from './circleButton.module.scss';

const CircleArrowButton = props =>
  props.isButton ? (
    <button
      className={`${
        props.active || typeof props.active === 'undefined'
          ? style.circleArrow
          : style.inactiveCircleArrow
      } ${props.direction === 'left' ? style.prev : ''}`}
      onClick={props.handleClick}
      onMouseLeave={e => {
        // don't preserve focus state if mouseing out after click
        e.target.blur();
      }}
    />
  ) : (
    <div
      className={`${
        props.active || typeof props.active === 'undefined'
          ? style.circleArrow
          : style.inactiveCircleArrow
      } ${props.direction === 'left' ? style.prev : ''}`}
      onClick={props.handleClick}
    />
  );

export default CircleArrowButton;
