// extracted by mini-css-extract-plugin
export var bgWhite = "fellows-module--bgWhite--lt6bT";
export var bgGrey = "fellows-module--bgGrey--D-FSW";
export var sectionHeading = "fellows-module--sectionHeading--PUI5a";
export var bioImage = "fellows-module--bioImage--v57l5";
export var staffName = "fellows-module--staffName--pU-15";
export var staffPosition = "fellows-module--staffPosition--QkhE0";
export var socialWrap = "fellows-module--socialWrap--xG6SL";
export var fellowsStaffInset = "fellows-module--fellowsStaffInset--oaNow";
export var fellowItem = "fellows-module--fellowItem--Zg7FI";
export var slideUp = "fellows-module--slideUp--UeDlZ";
export var slideUpLarge = "fellows-module--slideUpLarge--nKEzj";