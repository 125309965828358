import React from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import SpotlightCard from '../cards/spotlightCard';
import CircleArrowButton from '../ui/circleArrowButton';
import * as style from './projectSpotlight.module.scss';

class ProjectSpotlight extends React.Component {
	constructor() {
		super();
	}
	
	componentDidMount() {
	}
	
	// const swiper = useSwiper();
	
	render() {
		const {
			label,
			title,
			subtitle,
			items,
		} = this.props.data;

		const cards = items.map((s, i) => (
			<SwiperSlide>
				<SpotlightCard data={s} />
			</SwiperSlide>
		))
		
		const SwiperButtonNext = ({ children }) => {
			const swiper = useSwiper();
			
			return (
				<div
					className={'swiperNext'}
					onClick={() => swiper.slideNext()}
				>
					{children}
				</div>
			);
		};
		
		const SwiperButtonPrev = ({ children }) => {
			const swiper = useSwiper();
			
			return (
				<div
					className={'swiperPrev'}
					onClick={() => swiper.slidePrev()}
				>
					{children}
				</div>
			);
		};
		
		return (
			<div
				id={this.props.id}
				className={`${this.props.className} ${style.spotlight}`}
			>
				<div className={style.main}>
					<div className={style.label}>{label}</div>
					<div className={style.title}>{title}</div>
					<div className={style.subtitle}>{subtitle}</div>
				</div>
				<Swiper
					breakpoints={{
						980: {slidesPerView: 2.15}
					}}
					spaceBetween={20}
					slidesPerView={1.15}
					centeredSlides={false}
					centerInsufficientSlides={false}
				>
					{cards}
					
					<div
						className={'swiperNavWrap'}
					>
						<SwiperButtonPrev>
							<CircleArrowButton
								direction="left"
								active
								handleClick={this.moveLeft}
								isButton
							/>
							<span>PREV</span>
						</SwiperButtonPrev>
						
						<SwiperButtonNext>
							<span>NEXT</span>
							<CircleArrowButton
								direction="right"
								active
								isButton
							/>
						</SwiperButtonNext>
					</div>

					{/* <button onClick={() => swiper.slideNext()}>Slide to the next slide</button> */}
				</Swiper>
				
				<div>
					
				</div>
			</div>
		)
	}
};

export default ProjectSpotlight;