import { graphql, Link } from 'gatsby';
import React, { Component } from 'react';
import CardGrid from '../components/cards/cardGrid';
import ProjectCard from '../components/cards/projectCard';
import HeadTags from '../components/global/headTags';
import StaticHeroSimple from '../components/global/staticHeroSimple';
import ProjectSpotlight from '../components/projectSpotlight/projectSpotlight';
import DefaultLayout from '../layouts/default-layout';
import basicMapDataToComponents from './basicMapDataToComponents';
import SectionNavigation from '../components/corecurriculum/sectionNavigation';
import { makeAnchor } from '../components/article/faqList';
import * as style from './projects.module.scss';
import LogoSvg from '../components/global/logoSvg';
import Fellows from '../components/article/fellows';

class Projects extends Component {
	constructor() {
		super();
		this.state = {
			startPage: false,
			isExiting: false,
			width: 0,
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		// issue where first page click doesn't transition properly, so manually doing
		// transition here.
		if (nextProps.transition.status === 'exiting') {
			this.setState({ isExiting: true });
		}
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.setState({
			isExiting: false,
		});
		setTimeout(this.startPage, 1000);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}

	startPage = () => {
		this.setState({ startPage: true });
	};

	render() {
		const {
			title,
			meta_description,
			featured_image,
			paragraphs,
			collaborations,
			fellowship_stories
		} = this.props.data.allItemsJson.edges[0].node;
		
		const fellows = this.props.data.fellows.edges;
		// console.log('fellows');
		// console.log(fellows);
		
		const partners = this.props.data.partners.edges;
		// console.log('partners');
		// console.log(partners);
		
		// console.log('fellowship_stories');
		// console.log(fellowship_stories);

		const sections = [];
		
		sections.push({
			section_heading: 'Project News',
		});
		
		sections.push({
			section_heading: 'Our Fellows',
		});
		
		if (fellowship_stories.length > 0) {
			sections.push({
				section_heading: 'Fellowship Stories',
			});
		}
		
		sections.push({
			section_heading: 'Our Partners',
		});
		
		if (collaborations.length > 0) {
			sections.push({
				section_heading: 'Collaborations',
			});
		}
		
		// console.log('collaborations');
		// console.log(collaborations);

		var bottomCallouts = paragraphs.filter(p => p.type === 'bottom_callouts');
		
		// console.log('bottomCallouts');
		// console.log(bottomCallouts);
		
		
		const lists = paragraphs.filter(d => d.type === 'project_list');
		const spotlight = lists.splice(0, 1)[0];
		
		/*
		const items = lists.map((item, index) => {
			const label = item.label;
			const items = item.items;
			
			sections.push({
				section_heading: item.label,
			});
			return (
				<div id={makeAnchor(item.label)}>
					<CardGrid
						className={`${style.cardGrid}`}
						key={index}
						cardComponent={ProjectCard}
						label={label}
						items={items}
						link={item.cta_link}
					/>
				</div>
			)
		});
		*/
		
		/*
		<div className={`${style.container}`}>
			{items}
		</div>
		*/
		
		return (
			<DefaultLayout {...this.props} excludeParentFunctions>
				<div>
					<HeadTags
						title={title}
						description={meta_description}
						image={featured_image ? featured_image.image.sizes.hero_md : null}
						imageTwitter={
							featured_image ? featured_image.image.sizes.hero_sm : null
						}
						location={this.props.location}
					/>
					
					<div className={style.logo}>
						<Link to="/">
							<LogoSvg />
						</Link>
					</div>
					
					<div className={style.spacer}>&nbsp;</div>
					
					<StaticHeroSimple
						featuredImage={featured_image}
						title={title}
						description={meta_description}
					/>
					
					<div className={style.fellowshipSpotlightWrap}>
						<ProjectSpotlight
							id={'ProjectNews'}
							data={spotlight}
							bgColor={'#F1F1F1'}
						/>
					</div>
					
					<Fellows
						id={'OurFellows'}
						fellows={fellows}
					/>
					
					{/* FELLOWSHIP STORIES */}
					{fellowship_stories && fellowship_stories.length > 0 &&
						<div
							id={'FellowshipStories'}
							className={style.fellowshipStories}
						>
							<h2 className={`${style.sectionHeading}`}>
								FELLOWSHIP STORIES
							</h2>
							<div
								className={`${style.sectionInset}`}
							>
								{fellowship_stories && fellowship_stories.length > 0 && fellowship_stories.map(function(item, i) {
									var authors = (item.authors) ? item.authors.map((author) => author.name).join(' / ') : null;
	
									return (
										<div
											className={`${style.sectionItem}`}
										>
											<a href={item.path}>
												<div
													className={`${style.storyImg}`}
													style={{ backgroundImage: `url(${item.featured_image.sizes.hero_sm})` }}
												></div>
											</a>
											<h3
												className={`${style.storyTitle}`}
											>
												<a href={item.path}>
													{item.title}&nbsp;<span className={style.angle}>&#8250;</span>
												</a>
											</h3>
											
											{/*
											{item.partner &&
												<div
													className={`${style.partner}`}
												>
													In Partnership with <span>{item.partner}</span>
												</div>
											}
											
											{authors &&
												<div
													className={`${style.authors}`}
												>
													By <span className={`${style.authorName}`}>{authors}</span>
												</div>
											}
											*/}
										</div>
									);
								})}
								
							</div>
						</div>
					}
					{/* */}
					
					<div
						id={'OurPartners'}
						className={style.partnersSection}
					>
						<h2 className={`${style.sectionHeading}`}>
							OUR PARTNERS
						</h2>
						<div
							className={`${style.sectionInset}`}
						> 
							{partners && partners.length > 0 && partners.map(function(item, i) {
									return (
										<div
											className={`${style.sectionItem} ${style.partnerItem}`}
										>
											<a
												href={item.node.partner_url}
												target="_blank"
											>
												{/* {item.node.title} */}
												{item.node.partner_logo &&
													<img
														className={`${style.partnerLogo}`}
														src={item.node.partner_logo}
													/>
												}
											</a>
										</div>
									);
							})}
						</div>
					</div>
					
					<div
						id={'Collaborations'}
						className={style.collaborationsSection}
					>
						<h2 className={`${style.sectionHeading}`}>
							COLLABORATIONS
						</h2>
						<div
							className={`${style.sectionInset} ${style.collabInset}`}
						>
							{collaborations && collaborations.length > 0 && collaborations.map(function(item, i) {
								// var authors = (item.authors) ? item.authors.map((author) => author.name).join(' / ') : null;

								return (
									<div
										className={`${style.sectionItem} ${style.collabItem}`}
									>
										<a href={item.path}>
											<div
												className={`${style.collabImg}`}
												style={{ backgroundImage: `url(${item.featured_image.sizes.hero_sm})` }}
											></div>
										</a>
										<h3
											className={`${style.collabTitle}`}
										>
											<a href={item.path}>
												{item.title}&nbsp;<span className={style.angle}>&#8250;</span>
											</a>
										</h3>
										
										{item.collaboration_partnership &&
											<div
												className={`${style.partner}`}
											>
												<span>{item.collaboration_partnership}</span>
											</div>
										}
										
										{/*
										{authors &&
											<div
												className={`${style.authors}`}
											>
												By <span className={`${style.authorName}`}>{authors}</span>
											</div>
										}
										*/}
									</div>
								);
							})}

							{/*
								{item.authors && item.authors.length > 0 && item.authors.map(function(author, i) {
									{author.name}
								})}
							*/}
							
						</div>
					</div>
					
					<SectionNavigation sections={sections} currentHash={this.props.location.hash} />
					{bottomCallouts && bottomCallouts.length > 0 &&
						basicMapDataToComponents(bottomCallouts, { reveal: true })
					}
				</div>
			</DefaultLayout>
		);
	}
}

export default Projects;

/*
authors {
  id
  name
}

authors {
  id
  name
}
partner
*/

// eslint-disable-next-line
export const corecurriculumPageQuery = graphql`
query ProjectsPage(
	$path: String!
	$menuId: String!
	$isEs: Boolean!
	$idRegex: String!
	$blog: String!
	$translations: String!) {
	allItemsJson(filter: { path: { eq: $path } }) {
	  edges {
		node {
		  path
		  meta_description
		  short_description
		  title
		  related_challenge {
			id
			title
			path
		  }
		  featured_image {
			image {
			  sizes {
				hero_lg
				hero_md
				hero_sm
				original
			  }
			}
		  }
		  featured_video {
			caption
			url
			id
			image {
			  title
			  sizes {
				original
				standard_md
				standard_sm
			  }
			}
		  }
		  video {
			url
		  }
		  video_embed {
			id
			url
			name
		  }
		  collaborations {
			  id
			  title
			  path
			  collaboration_partnership
			  featured_image {
				  sizes {
						hero_lg
						hero_md
						hero_sm
						original
				  }
			  }
				partner
			}
		  fellowship_stories {
			  id
			  title
			  path
			  featured_image {
				  sizes {
						hero_lg
						hero_md
						hero_sm
						original
				  }
			  }
			}
		  ...queryParagraphFields
		}
	  }
	}
	social: allItemsJson(filter: { jsonId: { eq: $menuId } }) {
	  edges {
		node {
		  menu_social {
			title
			menu_name
			weight
			url
			class
			target
		  }
		}
	  }
	}
	fellows: allItemsJson(
		filter: {content_type: {eq: "author"}, staff_type: {eq: "Fellows"}, testing: {eq: false}},
		sort: {fields: author_sort, order: ASC}
	) {
		edges {
			node {
				jsonId
				title
				position
				staff_type
				bio_image
				twitter_link
				linkedin_link {
					uri
					title
				}
				slug
				author_sort
				testing
			}
		}
	}
	partners: allItemsJson(
		filter: {content_type: {eq: "partner"}, show_on_projects: {eq: true}, testing: {eq: false}},
		sort: {fields: partner_sort, order: ASC}
	) {
		edges {
			node {
				jsonId
				title
				description
				partner_logo
				partner_url
				partner_sort
				testing
			}
		}
	}
	...headerFields @skip(if:$isEs)
	...footerFields @skip(if:$isEs)
	...headerFieldsEs @include(if:$isEs)
	...footerFieldsEs @include(if:$isEs)
	challenges: allItemsJson(
		filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			related_challenge {
				title
				path
			}
			core_curriculum {
				title
				path
			}
			}
		}
		}
		pages: allItemsJson(
		filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
		edges {
			node {
			translations {
				id
				key
				value
			}
			}
		}
	}
	megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
			projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
  }  
`;