import React from 'react';
import * as style from './staticHeroSimple.module.scss';

class StaticHeroSimple extends React.Component {
	render() {
		const contain = this.props.contain ? style.maxWidth : '';

		return (
			<div>
				<div
					className={`${style.container} ${contain} ${this.props.small ? style.smallHeight : ''}`}
				>
					<div className={style.title}>{this.props.title}</div>
					<div className={style.description}>{this.props.description}</div>
				</div>
			</div>
		)
	}
}

export default StaticHeroSimple;